<template>
  <div class="reset-password-container">
    <v-card max-width="400" :disabled="loading">
      <v-card-text>
        <h1 class="mb-8">Reset Password</h1>

        <v-alert
          color="error"
          variant="tonal"
          density="comfortable"
          class="mb-5"
          v-if="isResponseError"
        >
          {{ responseMessage }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitResetPassword"
          validate-on="submit"
        >
          <v-text-field
            class="mb-2"
            ref="email"
            v-model="form.email"
            label="Email"
            color="primary"
            variant="outlined"
            density="comfortable"
            autocomplete="email"
            :rules="[rules.required, rules.validEmail]"
            disabled
          ></v-text-field>

          <v-text-field
            class="mb-2"
            v-model="form.password"
            label="Password"
            color="primary"
            variant="outlined"
            density="comfortable"
            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append-inner="showPassword = !showPassword"
            :rules="[
              rules.required,
              rules.minimum(6),
              rules.maximum(100),
              rules.confirmPassword(form.password, form.password_confirmation),
            ]"
          ></v-text-field>

          <v-text-field
            v-model="form.password_confirmation"
            label="Confirm Password"
            color="primary"
            variant="outlined"
            density="comfortable"
            :append-inner-icon="
              showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off'
            "
            :type="showPasswordConfirmation ? 'text' : 'password'"
            @click:append-inner="
              showPasswordConfirmation = !showPasswordConfirmation
            "
            :rules="[
              rules.required,
              rules.minimum(6),
              rules.maximum(100),
              rules.confirmPassword(form.password_confirmation, form.password),
            ]"
          ></v-text-field>

          <v-btn
            :loading="loading"
            type="submit"
            color="primary"
            class="mt-5"
            block
            text="Submit"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </div>

  <v-dialog v-model="successDialog" width="auto" persistent>
    <v-card
      max-width="400"
      prepend-icon="mdi-check-circle"
      :text="responseMessage"
      title="Password Reset"
    >
      <template v-slot:prepend>
        <v-icon color="success" size="x-large"></v-icon>
      </template>

      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          variant="tonal"
          text="Ok"
          :to="{ name: 'login' }"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules";
import axios from "axios";

export default {
  data: () => ({
    rules: formRules,
    showPassword: false,
    showPasswordConfirmation: false,
    successDialog: false,
    isResponseError: false,
    responseMessage: "",
    loading: false,
    form: {},
  }),

  mounted() {
    this.form.email = this.$route.query.email;
    this.form.token = this.$route.query.token;
  },

  methods: {
    async submitResetPassword() {
      this.isResponseError = false;
      this.responseMessage = "";

      const formRef = await this.$refs.form.validate();

      if (!formRef.valid || this.loading) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await axios.post("reset-password", this.form);

        this.responseMessage = data.message;

        if (data.type === "success") {
          this.successDialog = true;
        } else {
          this.isResponseError = true;
        }
      } catch (error) {
        alert("The system encountered an error. Please refresh the page.");
      } finally {
        this.loading = false;
        this.form.password = "";
        this.form.password_confirmation = "";
      }
    },
  },
};
</script>

<style scoped>
.reset-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.v-card {
  width: 400px;
  padding: 10px;
}
</style>
