const formRules = {
  required: v => !!v || 'This field is required.',
  minimum: (min) => { return v => !v || (v.trim() || '').length >= min || `Minimum ${min} characters.` },
  maximum: (max) => { return v => !v || (v.trim() || '').length <= max || `Maximum ${max} characters.` },
  numeric: v => {
    if (v) return /^\d+$/.test(v) || 'This field must contain only numbers.'
    else return true
  },
  alphaSpace: v => {
    if (v) return /^[a-zA-Z ]*$/.test(v) || 'This field may only contain alphabetical characters and spaces.'
    else return true
  },
  validEmail: v => {
    if (v) return /.+@.+\..+/.test(v) || 'E-mail must be valid format'
    else return true
  },
  validMobileNumber: v => {
    if (v) return /^(9)\d{9}$/.test(v) || 'Please enter a valid mobile number.'
    else return true
  },
  strongPassword: v => /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[a-z])/.test(v) || 'Must contains at least one upper case, one lower case and one number.',
  fileSize: (fileSize) => { return v => !v || v.size < (fileSize * 1000000) || `File size must not exceed ${fileSize}MB, you have selected a file with ${(v.size / 1000000).toFixed(2)}MB size.` },
  confirmPassword: (password1, password2) => password1 === password2 || 'Passwords must match',
      
}

export default formRules