import axios from "axios";
import router from "@/router";

export default {
    namespaced: true,

    state: {
        loginLoading: false,
        loginError: null,
        registerLoading: false,
        errors: [],
        verifyAccountLoading: false,
        token: null,
        user: null,
    },

    getters: {
        authenticated(state) {
            return state.token && state.user;
        },
        user: (state) => state.user,
        permissions(state) {
            if (state.user) {
                return state.user.user_permission;
            }

            return [];
        },
    },

    mutations: {
        setLoginLoading: (state, data) => (state.loginLoading = data),
        setLoginError: (state, data) => (state.loginError = data),
        setRegisterLoading: (state, data) => (state.registerLoading = data),
        setErrors: (state, payload) => (state.errors = payload),
        setVerifyAccountLoading: (state, data) => (state.verifyAccountLoading = data),
        setToken: (state, data) => (state.token = data),
        setUser: (state, data) => (state.user = data),

        resetStates(state) {
            state.token = null;
            state.user = null;
        },
    },

    actions: {
        async login({ dispatch, commit }, data) {
            commit("setLoginLoading", true);
            commit("setLoginError", false);

            try {
                const response = await axios.post("login", data);

                return dispatch("attempt", response.data.token);
            } catch (error) {
                if (error.response.status === 401) {
                    commit("setLoginError", error.response.data.message);
                } else {
                    alert("The system encountered an error. Please refresh the page.");
                }

                commit("setLoginLoading", false);
            }
        },

        async attempt({ commit, state }, data) {
            if (data) {
                commit("setToken", data);
            }

            if (!state.token) {
                return;
            }

            try {
                const response = await axios.get("user");
                commit("setUser", response.data);
            } catch (e) {
                commit("setToken", null);
                commit("setUser", null);
            } finally {
                if (state.loginLoading === true) {
                    commit("setLoginLoading", false);
                }
            }
        },

        async register({ commit }, data) {
            commit("setRegisterLoading", true);
            commit("setErrors", []);

            try {
                const response = await axios.post("register", data);

                return response;
            } catch (error) {
                if (error.response.status === 422) {
                    commit("setErrors", error.response.data.errors);
                } else {
                    alert("The system encountered an error. Please refresh the page.");
                }
            } finally {
                commit("setRegisterLoading", false);
            }
        },

        async verifyAccount({ commit }, data) {
            commit("setVerifyAccountLoading", true);

            try {
                const response = await axios.get(`verify-account/${data}`, {
                    headers: {
                        Authorization: "Basic bGNnaS1ocjpkYXB+MTIzIQ==",
                    },
                });

                if (response.data.type === "warning") {
                    router.replace({ name: "login" });
                }
            } catch (error) {
                alert("The system encountered an error. Please refresh the page.");
                router.replace({ name: "pageNotFound" });
            } finally {
                commit("setVerifyAccountLoading", false);
            }
        },

        async logout({ commit }) {
            try {
                await axios.post("logout");

                commit("resetStates", "");
                commit("setToken", null);
                commit("setUser", null);
            } catch (error) {
                alert("The system encountered an error. Please refresh the page.");
            }
        },

        async doOauth({ dispatch }, data) {
            try {
                const response = await axios.get(`oauth/${data}`, {
                    headers: {
                        Authorization: "Basic bGNnaS1ocjpkYXB+MTIzIQ==",
                    },
                });

                return dispatch("attempt", response.data.token);
            } catch (error) {
                alert("The system encountered an error. Please refresh the page.");
            }
        },
    },
};
