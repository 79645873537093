<template>
  <v-footer class="text-center d-flex flex-column py-5">
    <div class="mb-5">
      <v-btn
        v-for="icon in icons"
        :key="icon.title"
        :icon="icon.title"
        :color="icon.color"
        class="mx-4"
        variant="text"
      ></v-btn>
    </div>

    <p>© {{ new Date().getFullYear() }} Las Casas Gaming, Inc. HRIS</p>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { title: "mdi-facebook", color: "primary" },
      { title: "mdi-twitter", color: "primary" },
      { title: "mdi-linkedin", color: "primary" },
      { title: "mdi-instagram", color: "red" },
    ],
  }),
};
</script>