<template>
  <v-container>
    <v-row justify="center" v-if="verifyAccountLoading">
      <v-col cols="12" sm="6" md="4">
        <v-skeleton-loader type="article"></v-skeleton-loader>
      </v-col>
    </v-row>

    <div class="text-center mt-3" v-else>
      <img
        src="https://img.icons8.com/3d-fluency/94/partying-face-1.png"
        style="width: 50px; height: 50px"
        class="ml-2"
      />
      <h1 class="font-weight-black text-success">Congratulations!</h1>
      <h3>Your account has been verified.</h3>
      <p class="my-5">
        You will be redirected to the login page in
        <span class="font-weight-bold text-primary">{{ value }}</span>
        seconds.
      </p>

      <v-btn
        to="/login"
        color="primary"
        variant="outlined"
        :disabled="verifyAccountLoading"
        class="text-uppercase"
      >
        Go to Login
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      interval: {},
      value: 10,
    };
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  mounted() {
    this.verifyAccount();
  },

  computed: {
    ...mapState("auth", ["verifyAccountLoading"]),
  },

  methods: {
    async verifyAccount() {
      if (!this.verifyAccountLoading) {
        await this.$store.dispatch("auth/verifyAccount", this.$route.params.id);

        this.interval = setInterval(() => {
          if (this.value === 1) {
            this.$router.replace({ name: "login" });
          }

          this.value -= 1;
        }, 1000);
      }
    },
  },
};
</script>