import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import vuetify from './plugins/vuetify'
import "@/assets/main.css";

require("@/store/subscriber");

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
    const app = createApp(App)

    app.use(vuetify)
    app.use(store)
    app.use(router)
    app.mount('#app')
});