<template>
  <v-container>
    <div class="text-center mt-3">
      <img
        src="https://img.icons8.com/3d-fluency/94/maintenance.png"
        style="width: 60px; height: 60px"
        class="ml-2"
      />
      <h1 class="font-weight-black text-info">Page Under Maintenance</h1>
      <h3>
        We apologize for the inconvenience, but this page is currently
        undergoing maintenance.
      </h3>
      <p class="my-5">
        You will be redirected to the jobs page in
        <span class="font-weight-bold text-primary">{{ value }}</span>
        seconds.
      </p>

      <v-btn
        :to="{ name: 'jobs' }"
        color="primary"
        variant="outlined"
        class="text-uppercase"
      >
        Go to Jobs
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      interval: {},
      value: 10,
    };
  },

  beforeUnmount() {
    clearInterval(this.interval);
  },

  mounted() {
    this.interval = setInterval(() => {
      if (this.value === 1) {
        this.$router.replace({ name: "jobs" });
      }

      this.value -= 1;
    }, 1000);
  },
};
</script>