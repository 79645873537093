<template>
  <v-dialog v-model="logout" persistent width="300">
    <v-card>
      <v-card-text>
        Logging out
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    logout: false,
  }),

  methods: {
    show() {
      this.logout = true;
    },

    hide() {
      this.logout = false;
    },
  },
};
</script>