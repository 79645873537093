import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import AboutUs from '../views/AboutUs.vue'
import Faq from '../views/Faq.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import VerifyAccount from '../views/VerifyAccount.vue'
import OAuth from '../views/OAuth.vue'

const guest = (to, from, next) => {
  if (!store.getters['auth/authenticated']) {
    return next()
  } else {
    return next({ name: 'jobs' })
  }
}

const auth = (to, from, next) => {
  if (store.getters['auth/authenticated']) {
    return next()
  } else {
    return next({ name: 'login' })
  }
}

const isAdmin = (to, from, next) => {
  if (store.getters['auth/authenticated'] && store.getters['auth/user'].role_id === 1) {
    return next()
  } else {
    return next({ name: 'jobs' })
  }
}

const isEmployee = (to, from, next) => {
  if (store.getters['auth/authenticated'] && store.getters['auth/user'].role_id !== 3) {
    return next()
  } else {
    return next({ name: 'jobs' })
  }
}

const routes = [
  {
    path: '/jobs',
    alias: "/",
    name: 'jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue'),
  },
  {
    path: '/about-us',
    name: 'aboutUs',
    component: AboutUs,
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: guest,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: guest,
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: ForgotPassword,
    beforeEnter: guest,
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword,
    beforeEnter: [guest, (to, from, next) => {
      if (!to.query.token || !to.query.email) {
        next({ name: 'login' })
      } else {
        next();
      }

    }],
  },
  {
    path: '/verify-account/:id',
    name: 'verifyAccount',
    component: VerifyAccount,
    beforeEnter: guest,
  },
  {
    path: '/oauth/:id',
    component: OAuth,
    beforeEnter: guest,
    meta: {
      hideNavbar: true,
      hideFooter: true,
    }
  },
  {
    path: '/jobs/:id',
    name: 'viewJob',
    // route level code-splitting
    // this generates a separate chunk (viewJob.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "viewJob" */ '../views/job/View.vue'),
    beforeEnter: auth,
  },
  {
    path: '/account',
    name: 'account',
    // route level code-splitting
    // this generates a separate chunk (account.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    beforeEnter: auth,
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "account" */ '../views/account/Profile.vue'),
    beforeEnter: auth,
  },
  {
    path: '/job-applications',
    name: 'jobApplications',
    component: () => import(/* webpackChunkName: "account" */ '../views/account/JobApplications.vue'),
    beforeEnter: auth,
  },
  {
    path: '/attendance',
    name: 'attendance',
    component: () => import(/* webpackChunkName: "account" */ '../views/account/Attendance.vue'),
    beforeEnter: isEmployee,
  },
  {
    path: '/my-leaves',
    name: 'myLeaves',
    component: () => import(/* webpackChunkName: "account" */ '../views/account/MyLeaves.vue'),
    beforeEnter: isEmployee,
  },
  {
    path: '/my-payroll',
    name: 'myPayroll',
    component: () => import(/* webpackChunkName: "account" */ '../views/account/MyPayroll.vue'),
    beforeEnter: isEmployee,
  },
  {
    path: '/admin/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Dashboard.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Dashboard'
    }
  },
  {
    path: '/admin/companies',
    name: 'companies',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Companies.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Companies'
    }
  },
  {
    path: '/admin/departments',
    name: 'departments',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Departments.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Departments'
    }
  },
  {
    path: '/admin/attendances',
    name: 'attendances',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Attendances.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Attendances'
    }
  },
  {
    path: '/admin/permissions',
    name: 'permissions',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Permissions.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Permissions'
    }
  },
  {
    path: '/admin/payrolls',
    name: 'payrolls',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Payrolls.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Payrolls'
    }
  },
  {
    path: '/admin/admins',
    name: 'admins',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Admins.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Admins'
    }
  },
  {
    path: '/admin/employees',
    name: 'employees',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Employees.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Employees'
    }
  },
  {
    path: '/admin/applicants',
    name: 'applicants',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Applicants.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Applicants'
    }
  },
  {
    path: '/admin/jobs',
    name: 'adminJobs',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Jobs.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Jobs'
    }
  },
  {
    path: '/admin/applications',
    name: 'applications',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Applications.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Applications'
    }
  },
  {
    path: '/admin/qualifications',
    name: 'qualifications',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Qualifications.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Qualifications'
    }
  },
  {
    path: '/admin/responsibilities',
    name: 'responsibilities',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Responsibilities.vue'),
    beforeEnter: isAdmin,
    meta: {
      adminView: true,
      title: 'Responsibilities'
    }
  },
  {
    path: '/:catchAll(.*)*',
    name: 'pageNotFound',
    component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue'),
  },
  {
    path: '/face-recognition',
    alias: "/",
    name: 'faceRecognition',
    component: () => import('@/views/FaceRecognition'),
    meta: {
      title: 'FaceRecognition'
    }
  },
  {
    path: '/attendance/clock-in',
    alias: "/",
    component: () => import('@/views/attendance/ClockIn'),
    meta: {
      title: 'FaceRecognition'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
