<template>
  <v-app id="app">
    <Navigation v-if="!$route.meta.hideNavbar && !$route.meta.adminView" />
    <AdminNavigation v-if="$route.meta.adminView" />

    <v-main>
      <router-view />
    </v-main>

    <Footer v-if="!$route.meta.hideFooter && !$route.meta.adminView" />
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import AdminNavigation from "@/components/admin/Navigation.vue";
import axios from "axios";
import ph_provinces from "@/data/ph_provinces.json";
import ph_cities from "@/data/ph_cities.json";

export default {
  name: "App",

  components: {
    Navigation,
    Footer,
    AdminNavigation,
  },

  created() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.handleGeoSuccess,
        this.handleGeoError
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }

    this.loadRecords();

    this.$store.commit("setProvinces", ph_provinces);
    this.$store.commit("setCities", ph_cities);
  },

  methods: {
    handleGeoSuccess(position) {
      const geolocation = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      this.$store.commit("setGeolocation", geolocation);
    },

    handleGeoError(error) {
      this.$store.commit("setGeoError", error);
    },

    async loadRecords() {
      try {
        const response = await axios.get("app-data", {
          headers: {
            Authorization: process.env.VUE_APP_BASIC_AUTH,
          },
        });

        this.$store.commit("setDepartments", response.data.departments);
        this.$store.commit("setEmploymentTypes", response.data.employmentTypes);
        this.$store.commit("setLeaveTypes", response.data.leaveTypes);
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>