<template>
  <div class="oauth-container">
    <div class="loader"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["authenticated"]),
  },

  mounted() {
    this.$store.dispatch("auth/doOauth", this.$route.params.id);
  },

  watch: {
    authenticated(val) {
      if (val) {
        this.$router.replace({ name: "jobs" });
      }
    },
  },
};
</script>

<style scoped>
.oauth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3f51b5;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>