<template>
  <div class="forgot-password-container">
    <v-card max-width="400" :disabled="loading">
      <v-card-text>
        <h1 class="mb-8">Forgot Password</h1>

        <v-alert
          color="error"
          variant="tonal"
          density="comfortable"
          class="mb-5"
          v-if="isResponseError"
        >
          {{ responseMessage }}
        </v-alert>
        <v-form
          ref="form"
          @submit.prevent="submitForgotPassword"
          validate-on="submit"
        >
          <v-text-field
            ref="email"
            v-model="form.email"
            label="Email"
            color="primary"
            variant="outlined"
            density="comfortable"
            autocomplete="email"
            :rules="[rules.required, rules.validEmail]"
          ></v-text-field>

          <v-btn
            :loading="loading"
            type="submit"
            color="primary"
            class="mt-5"
            block
            text="Submit"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </div>

  <v-dialog v-model="successDialog" width="auto" persistent>
    <v-card
      max-width="400"
      prepend-icon="mdi-check-circle"
      :text="responseMessage"
      title="Email Sent"
    >
      <template v-slot:prepend>
        <v-icon color="success" size="x-large"></v-icon>
      </template>

      <template v-slot:actions>
        <v-btn
          class="ms-auto"
          variant="tonal"
          text="Ok"
          @click="successDialog = false"
        ></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import formRules from "@/helpers/formRules";
import axios from "axios";

export default {
  data: () => ({
    rules: formRules,
    successDialog: false,
    isResponseError: false,
    responseMessage: "",
    loading: false,
    form: {},
  }),

  methods: {
    async submitForgotPassword() {
      this.isResponseError = false;
      this.responseMessage = "";

      const formRef = await this.$refs.form.validate();

      if (!formRef.valid || this.loading) {
        return;
      }

      try {
        this.loading = true;

        const { data } = await axios.post("forgot-password", this.form);

        this.responseMessage = data.message;

        if (data.type === "success") {
          this.successDialog = true;
        } else {
          this.isResponseError = true;
        }
      } catch (error) {
        alert("The system encountered an error. Please refresh the page.");
      } finally {
        this.loading = false;
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped>
.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.v-card {
  width: 400px;
  padding: 10px;
}
</style>
