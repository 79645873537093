<template>
  <div class="login-container">
    <v-card max-width="400" :disabled="loginLoading">
      <v-card-text>
        <h1 class="mb-8">Login</h1>
        <v-alert
          color="error"
          variant="tonal"
          density="comfortable"
          class="mb-5"
          v-if="loginError"
        >
          {{ loginError }}
        </v-alert>
        <v-form ref="form" @submit.prevent="login" validate-on="submit">
          <v-text-field
            ref="email"
            v-model="form.email"
            label="Email"
            color="primary"
            variant="outlined"
            density="comfortable"
            autocomplete="email"
            :rules="[rules.required, rules.validEmail]"
          ></v-text-field>
          <div class="d-flex justify-end">
            <a
              class="text-caption text-decoration-none text-blue"
              style="cursor: pointer"
              @click.prevent="goToForgotPassword"
            >
              Forgot password?
            </a>
          </div>
          <v-text-field
            v-model="form.password"
            label="Password"
            color="primary"
            variant="outlined"
            density="comfortable"
            :append-inner-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append-inner="showPassword = !showPassword"
            autocomplete="current-password"
            :rules="[rules.required]"
            class="mb-2"
          ></v-text-field>
          <v-btn
            type="submit"
            color="primary"
            class="mb-5"
            :loading="loginLoading"
            block
            >Login</v-btn
          >
        </v-form>

        <div class="hr-or mb-5">or</div>

        <div class="mb-5">
          <v-btn variant="outlined" block class="text-none mb-2">
            <img
              width="25"
              height="25"
              src="https://img.icons8.com/color/48/google-logo.png"
              class="mr-1"
            />
            Continue with Google
          </v-btn>
          <v-btn variant="outlined" block class="text-none">
            <img
              width="25"
              height="25"
              src="https://img.icons8.com/color/48/facebook-new.png"
              class="mr-1"
            />
            Continue with Facebook
          </v-btn>
        </div>
        <div class="text-center">
          <p>Don't have an account?</p>
          <v-btn
            color="primary"
            variant="text"
            :to="{ name: 'register' }"
            density="compact"
          >
            Register
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import formRules from "@/helpers/formRules";
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({
    rules: formRules,
    form: {
      email: null,
      password: null,
    },
    showPassword: false,
  }),

  computed: {
    ...mapGetters("auth", ["authenticated"]),
    ...mapState("auth", ["loginLoading", "loginError"]),
  },

  mounted() {
    setTimeout(() => {
      this.$refs.email.focus();
    }, 100);
  },

  methods: {
    async login() {
      const formRef = await this.$refs.form.validate();

      if (formRef.valid && !this.loginLoading) {
        this.$store.dispatch("auth/login", this.form);
      }
    },

    goToForgotPassword() {
      this.$router.push({ name: "forgotPassword" });
    },
  },

  watch: {
    loginError(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.email.focus();
        }, 100);
      }
    },
    authenticated(val) {
      if (val) {
        this.$router.replace({ name: "jobs" });
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.v-card {
  width: 400px;
  padding: 10px;
}

.hr-or {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  margin: 8px 0px;
}

.hr-or:before,
.hr-or:after {
  content: "";
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}
</style>
