import { createStore } from 'vuex'
import auth from "./auth";

export default createStore({
  state: {
    geolocation: {},
    departments: [],
    employmentTypes: [],
    leaveTypes: [],
    provinces: [],
    cities: []
  },

  getters: {
  },

  mutations: {
    setGeolocation(state, val) {
      state.geolocation = val;
    },

    setGeoError(state, val) {
      switch (val.code) {
        case val.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.");
          break;
        case val.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.");
          break;
        case val.TIMEOUT:
          console.log("The request to get user location timed out.");
          break;
        case val.UNKNOWN_ERROR:
          console.log("An unknown error occurred.");
          break;
      }
    },

    setDepartments(state, val) {
      state.departments = val;
    },

    setEmploymentTypes(state, val) {
      state.employmentTypes = val;
    },

    setLeaveTypes(state, val) {
      state.leaveTypes = val;
    },

    setProvinces(state, val) {
      state.provinces = val;
    },

    setCities(state, val) {
      state.cities = val;
    },
  },

  actions: {
  },

  modules: {
    auth
  }
})
